// Footnotes
//
// uses:
// - Alpine.js
//

(function () {
  const mediaQuerySm = window.matchMedia('(min-width: ' + 900 / 16 + 'em)');

  document.addEventListener('alpine:init', () => {
    // attach to main wrapper that contains the footnote triggers
    // as well as the footnote items
    Alpine.data('footnotes', () => ({
      activeFootnote: null,
      activeFootnoteNote: null,
    }));

    // attach to each individual footnote item
    Alpine.data('footnoteNote', () => ({
      top: 0,
      left: 0,
      isOpen() {
        return (
          decodeURIComponent(this.activeFootnoteNote) + '-tooltip' ==
          this.$el.id
        );
      },
      close() {
        this.activeFootnote = null;
        this.activeFootnoteNote = null;
      },
      getActiveTrigger() {
        return (
          this.activeFootnote &&
          document.querySelector('#' + this.activeFootnote)
        );
      },
      getTrigger() {
        return document.querySelector('a[href="#' + this.$root.id + '"]');
      },
      recalc() {
        const trigger = this.getActiveTrigger();

        // sanity check
        if (!trigger) return;

        // set top position
        const position = trigger.getBoundingClientRect();
        this.top = position.top + window.scrollY + 'px';

        // calculate left position accounting for viewport edges
        const maxX =
          document.documentElement.clientWidth - this.$el.offsetWidth / 2 - 10;
        const minX = 10 + this.$el.offsetWidth / 2;
        const defaultX = position.left + position.width / 2 + window.scrollX;
        this.left = Math.max(Math.min(defaultX, maxX), minX) + 'px';
      },

      // attach to the "close" button on each individual footnote item
      closeButton: {
        ['x-on:click']() {
          this.close();
        },
      },

      backlink: {
        ['x-init']() {
          const trigger = this.getTrigger();
          if (trigger) {
            this.$el.href = '#' + trigger.id;
          } else {
            this.$el.remove();
          }
        },
      },

      // attach to the pop-up wrapper for each individual footnote item
      note: {
        ['x-init']() {
          this.$watch(
            'isOpen',
            (value, oldValue) =>
              value !== oldValue && value && this.$nextTick(() => this.recalc())
          );
        },
        ['x-show']() {
          return this.isOpen();
        },
        ['x-bind:style']() {
          return {
            top: this.top,
            left: this.left,
          };
        },
        ['x-on:keyup.escape.window']() {
          return this.isOpen() && this.close();
        },
        ['x-on:click.outside']() {
          return this.isOpen() && this.close();
        },
        ['x-on:resize.window.throttle']() {
          return this.recalc();
        },
      },
    }));

    Alpine.data('footnote', () => ({
      id: null,
      note: null,
      init() {
        this.id = this.$id('footnote');
        this.note = new URL(this.$el.href).hash.slice(1);
      },
      isOpen() {
        return (
          typeof this.activeFootnote !== 'undefined' &&
          this.activeFootnote === this.id
        );
      },
      toggle() {
        if (this.isOpen()) {
          this.activeFootnote = null;
          this.activeFootnoteNote = null;
        } else {
          this.activeFootnote = this.id;
          this.activeFootnoteNote = this.note;
        }
      },

      // attach to links in the text that point to the footnotes
      // eg: <a href="#fn-12-abc" x-data="footnote" x-bind="trigger">[1]</a>
      trigger: {
        [':id']() {
          return this.id;
        },
        ['@click.prevent']() {
          this.toggle();
        },
        [':class']() {
          return this.isOpen() ? 'active' : '';
        },
      },
    }));
  });
})();
